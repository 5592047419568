import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UserRole } from '../interfaces/common';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  stockCount: number;
  freeLoc: number;
  userName: string;
  userRole: string;

  constructor(
    private session: SessionService,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
    }

  ngOnInit() {
    this.getData();
    this.session.verify();
  }

  
  private getData() {
    this.http.get(this.baseUrl + 'api/stock/stats').subscribe(result => {
      this.stockCount = result["all"];
      this.freeLoc = result["free"];
      this.userName = this.session.userName;
      this.userRole = UserRole[this.session.userRole];
    }, error => console.error(error));
    setTimeout(() => { this.getData() }, 30000);
  }

}
