import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IWydanie } from '../interfaces/IWydanie';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-outflow-printout-component',
  templateUrl: './outflow-printout.component.html',
  styleUrls: ['./outflow-printout.component.css']

})
export class OutflowPrintoutComponent implements OnInit, AfterViewInit {

  outflowId: string;
  paczka: string;
  dataWz: Date;
  razem: number;
  ilePal: number;
  data: IWydanie[];
  printCode: boolean;

  constructor(
    private session: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }
  
  ngAfterViewInit(): void {
  }


  ngOnInit(): void {
    this.session.verify();
    this.printCode = this.route.snapshot.queryParams['code'] == "true";

    this.route.params.subscribe((params: ParamMap) => {
      this.outflowId = params['id'];


      if (this.outflowId) {

        this.http.get<IWydanie[]>(this.baseUrl + 'api/stock/outflowPrint/' + this.outflowId).subscribe(result => {
          this.data = result;

          if (this.data.length < 1) {
            return;
          }

          this.paczka = this.data[0].paczkaWydaniaOpis;
          this.dataWz = this.data[0].data;

          this.razem = 0;
          this.data.forEach(d => this.razem += d.ilosc);
          
          this.ilePal = this.data[0].ilePal;

          setTimeout(() => {
            window.print();
          }, 100);

        }, error => console.error(error));
      };
    });
  }
 
  checkSum(cdi: string) {
    return `*${cdi}*`;
  }
}