import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, OnInit, ElementRef, Inject } from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AssortmentFinderComponent } from '../assortment/assortment-finder.component';
import { AssortmentDialogComponent } from '../assortment/assortment.dialog.component';
import { UserRole } from '../interfaces/common';
import { IAssortment } from '../interfaces/IAssortment';
import { IVMagazynBiezacy, InflowResponseStatus } from '../interfaces/IVMagazyn';
import { IWydanie } from '../interfaces/IWydanie';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-inflow-component',
  templateUrl: './inflow.component.html',
  styleUrls: ['./inflow.component.css']
})
export class InflowComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(AssortmentFinderComponent, { static: true }) finder: AssortmentFinderComponent
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  fileAttr = 'Choose File';
  assortment: IAssortment;

  bremboDate: Date = new Date();;
  inflowDate: Date = new Date();;
  bremboDoc: string = "";
  operStatus: string = "";
  currentFile: File = null;
  loading: boolean = false;
  inflowId: number = 0;
  inflowNumber: string = "";
  columnList = ['asortymentKod', 'cdi', 'lokalizacjaOpis', 'lotto', 'asortymentOpis', 'ilosc'];
  showBack: boolean = false;

  private tableData: MatTableDataSource<IVMagazynBiezacy>;

  bremboDateFormControl = new UntypedFormControl('', [ Validators.required ]);
  inflowDateFormControl = new UntypedFormControl('', [ Validators.required ]);
  bremboDocFormControl = new UntypedFormControl('', [ Validators.required ]);

  constructor(
      public matDialogRef: MatDialogRef<AssortmentDialogComponent>,
      private dialog: MatDialog,
      private http: HttpClient,
      private route: ActivatedRoute,
      private router: Router,
      private session: SessionService,
      @Inject('BASE_URL') private baseUrl: string)
  { }

  ngOnInit() {
    this.session.verify(UserRole.ReadWrite);
    this.route.params.subscribe((params: ParamMap) => {
      this.inflowId = params['id'];

      if (this.inflowId) {
        this.inflowDateFormControl.disable();
        this.bremboDocFormControl.disable();
        this.bremboDateFormControl.disable();
        
        this.columnList.unshift('delete');
        this.http.get<IWydanie[]>(this.baseUrl + 'api/stock/inflowPrint/' + this.inflowId).subscribe(result => {

          let data: IVMagazynBiezacy[] = [];
          result.forEach(d => {
            console.debug();
            if (d.dataWydania.toString().substring(0, 4) == "0001") {
              d.dataWydania = null;
            }
            data.push({
              id: d.id,
              paczka: this.inflowId ? +this.inflowId : 0,
              paczkaOpis: d.paczkaPrzyjeciaOpis,
              paczkaWydaniaOpis: d.paczkaWydaniaOpis,
              dataPrzyjecia: d.dataPrzyjecia,
              dataWydania: d.dataWydania,
              lokalizacja: d.lokalizacja,
              lokalizacjaOpis: d.lokalizacjaOpis,
              asortyment: d.asortyment,
              asortymentKod: d.kod,
              asortymentOpis: d.nazwa,
              dokumentBrembo: d.dokumentBrembo,
              dataBrembo: d.dataBrembo,
              lotto1: "",
              lotto2: "",
              cdi: d.cdi,
              cdiBlok: false,
              ilosc: d.ilosc,
              lotto: d.lotto,
              uwagi: d.uwagi
            })
          });
          this.tableData = new MatTableDataSource<IVMagazynBiezacy>(data);
          this.tableData.paginator = this.paginator;
          if (data.length > 0) {
            this.inflowNumber = data[0].paczkaOpis;
            this.inflowDate = data[0].dataPrzyjecia;
            this.bremboDate = data[0].dataBrembo;
            this.bremboDoc = data[0].dokumentBrembo;
          }

        }, result => {
          console.error(result.error.errors);
          for (var e in result.error.errors) {
            this.operStatus += result.error.errors[e] + " ";
          }
        });
      };
    });


  }

  assortmentClick() {
    let dialogRef : MatDialogRef<AssortmentDialogComponent> = this.dialog.open(AssortmentDialogComponent);    
    dialogRef.componentInstance.onSelect.subscribe(value=>{     
      this.assortment = value;  
      this.finder.SetValue("");
    });

  }

  uploadFileEvt(imgFile: any) {
    this.operStatus = "";
    this.currentFile = null;
    this.loading = true;

    if (imgFile.target.files && imgFile.target.files[0]) {
      this.currentFile = imgFile.target.files[0];
      this.fileAttr = '';

      let reader = new FileReader();
      reader.onload = (e: any) => {

        this.http.post<InflowResponseStatus>(this.baseUrl + 'api/stock/importparse', { csvData: e.target.result }).subscribe(result => {
          if (!result.status) {
            this.tableData = new MatTableDataSource<IVMagazynBiezacy>(result.data);
            this.tableData.paginator = this.paginator;
            this.loading = false;
          } else {
            this.operStatus = result.status;
          }
        }, result => {
          console.error(result.error.errors);
          for (var e in result.error.errors) {
            this.operStatus += result.error.errors[e] + " ";
          }
        });

      };
      reader.readAsDataURL(imgFile.target.files[0]);

    } else {
      this.fileAttr = 'Choose File';
      
    }
  }

  save() {
    this.operStatus = "";
    if (!this.inflowId) {
      if (!(this.bremboDate && this.inflowDate && this.bremboDoc.length > 0)) {
        this.bremboDocFormControl.markAsTouched();
        this.inflowDateFormControl.markAsTouched();
        this.bremboDocFormControl.markAsTouched();
        return;
      }
      this.tableData.data.forEach(item => {
        item.dataBrembo = this.bremboDate;
        item.dataPrzyjecia = this.inflowDate;
        item.dokumentBrembo = this.bremboDoc;
      });
    }

    const ifid = this.inflowId == null ? 0 : +this.inflowId;
    this.http.post(this.baseUrl + 'api/stock/importsave', { data: this.tableData.data, id: ifid } ).subscribe(result => {
      if (this.inflowId > 0) {
        this.showBack = true;
        this.operStatus = "Zapis OK";
      } else {
        this.operStatus = "Import OK";
      }
      this.tableData = null;
    }, result => {
      console.error(result.error);
      if (result.error.errors) {
        for (var e in result.error.errors) {
          this.operStatus += result.error.errors[e] + " ";
        }
      } else {
        this.operStatus = result.error;
      }
    });
  }

  delete(item: IVMagazynBiezacy) {
    this.tableData.data = this.tableData.data.filter(d => 
      !(d.asortymentKod == item.asortymentKod && d.cdi == item.cdi && d.lotto == item.lotto));
  }

  back() {
    this.router.navigate(["inflow-history"]);
  }

}


