import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IAssortment } from '../interfaces/IAssortment';
import { IHistoria } from '../interfaces/IHistoria';
import { TableUtil } from '../tableUtils';
import { OutflowHistoryPositionsComponent } from './outflow-history-positions.component';

@Component({
  selector: 'app-outflow-history-dialog',
  templateUrl: './outflow-history.dialog.component.html'
  })
  
export class OutflowHistoryDialogComponent implements OnInit {
  @ViewChild(OutflowHistoryPositionsComponent, { static: true }) positions: OutflowHistoryPositionsComponent

  selectedItem: IAssortment;
  history: IHistoria;

  constructor(public matDialogRef: MatDialogRef<OutflowHistoryDialogComponent>,
    @Inject('BASE_URL') private baseUrl: string) {  }

  ngOnInit() {
    this.positions.historyId = this.history.id;
  }
  
  select() {
    this.matDialogRef.close();
  }
  
  cancel() {
    this.matDialogRef.close();  
  }

  print(kod: boolean) {
    window.open(`${this.baseUrl}reports/outflow/${this.positions.historyId}?code=${kod}`);
  }

  export() {
    this.positions.export();
  }

}
