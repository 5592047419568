import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    private session: SessionService,
    private router: Router) { 
  }

  ngOnInit(): void {
    this.session.verify(); 
  }
  
  isPrintoutView() {
    return this.router.url.match('/reports/inflow') || this.router.url.match('/reports/outflow') || this.router.url.match('/logon');
  }

}
