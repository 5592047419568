import { Component, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IAssortment } from '../interfaces/IAssortment';
import { AssortmentComponent } from './assortment.component';

@Component({
    selector: 'app-assortment-dialog',
    templateUrl: './assortment.dialog.component.html'
  })
  
export class AssortmentDialogComponent implements AfterViewInit {
  @ViewChild(AssortmentComponent, { static: true }) assortment: AssortmentComponent
  @Output() onSelect : EventEmitter<IAssortment> = new EventEmitter();

  onStock: boolean;
  selectedItem: IAssortment;
  constructor(public matDialogRef: MatDialogRef<AssortmentDialogComponent>) {  }

  ngAfterViewInit() {
    this.assortment.selectedItem.subscribe((value: { assortment: IAssortment; dblclick: any; }) => {
      this.selectedItem = value.assortment;
      if (value.dblclick) {
        this.select();
      }
    })
  }
  
  select() {
    this.onSelect.emit(this.selectedItem);    
    this.matDialogRef.close();
  }
  
  cancel() {
    this.matDialogRef.close();  
  }
}
