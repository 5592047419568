import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IVMagazynBiezacy } from '../interfaces/IVMagazyn';

@Component({
  selector: 'app-inflow-history-positions-component',
  templateUrl: './inflow-history-positions.component.html'
})
export class InflowHistoryPositionsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() historyId: number;


  private tableData: MatTableDataSource<IVMagazynBiezacy>;
  selectedRow: IVMagazynBiezacy;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    this.http.get<IVMagazynBiezacy[]>(this.baseUrl + 'api/inflow/positions/' + this.historyId).subscribe(result => {
      this.tableData = new MatTableDataSource<IVMagazynBiezacy>(result);
      this.tableData.paginator = this.paginator;
    }, error => console.error(error));
  }

  selectRow(row: IVMagazynBiezacy) {
    this.selectedRow = row;
  }

}
