export interface ToFind {
	what: string;
	mode: SearchMode;
}

export enum SearchMode {
	Full = "1",
	StartsWith = "2",
	EndWith = "3",
}

export interface ToLocationFind {
	what: string;
	mode: LocationSearchMode;
}

export enum LocationSearchMode {
	All = "1",
	OnlyFree = "2",
	OnlyOcuppied = "3",
}

export enum UserRole {
	None = 0,
	Super = 1,
	ReadWrite = 2,
	ReadOnly = 3,
}

export interface UserInfo {
	userName: string;
	sid: string;
	rola: UserRole;
}

export interface UserInfoResponse {
	data: UserInfo;
	status: string;
}
