import { Component, Inject, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAssortment } from '../interfaces/IAssortment';
import { Router } from '@angular/router';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-assortment',
  templateUrl: './assortment.component.html',
  styleUrls: ['./assortment.component.css']
})


export class AssortmentComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() selectedItem = new EventEmitter<any>();
  @Input() dialogMode: boolean;
  @Input() onStock: boolean = false;


  private tableData: MatTableDataSource<IAssortment>;
  private data: IAssortment[];

  private displayedColumns: string[];

  selectedRow: IAssortment;

  constructor(
    private route: Router,
    private http: HttpClient,
    private session: SessionService,
    @Inject('BASE_URL') private baseUrl: string) {    
  }

  ngOnInit() {
    this.session.verify();
    if (this.dialogMode) {
      this.displayedColumns = ['kod', 'opis', 'multiplo', 'waga'];
    } else {
      this.displayedColumns = ['history', 'kod', 'opis', 'multiplo', 'waga'];
    }

    this.http.get<IAssortment[]>(this.baseUrl + 'api/assort/get/' + this.onStock).subscribe(result => {
      this.data = result;
      this.tableData = new MatTableDataSource<IAssortment>(this.data);
      this.tableData.paginator = this.paginator;
      this.tableData.sort = this.sort;
    }, error => console.error(error));
  }

  private _sortAlphanumeric(a: string, b: string): number {
    return a.toString().localeCompare(b, 'en', { numeric: true });
  }
  
 sortData(sort: Sort): void {
    this.selectedRow = null;
    if (sort.direction === '') {
      this.tableData.data = this.data;
      return;
    }

    const sortedData = this.data.slice();
    var that = this;
    sortedData.sort(
      (a: IAssortment, b: IAssortment) => sort.direction === 'asc'
        ? that._sortAlphanumeric(a[sort.active], b[sort.active])
        : that._sortAlphanumeric(b[sort.active], a[sort.active])
    );
    this.tableData.data = sortedData;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.tableData.filter = filterValue;
    this.selectedRow = null;
  }

  selectRow(row: IAssortment) {
    this.selectedRow = row;
    this.selectedItem.emit( { assortment: row, dblclick: false});
  }
  
  chooseRow(row: IAssortment) {
    this.selectedRow = row;
    this.selectedItem.emit( { assortment: row, dblclick: true});
  }

  history(row: IAssortment) {
    this.route.navigate(["history", row.kod]);
  }
}