import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { ILoc } from '../interfaces/ILoc';

@Component({
    selector: 'app-location-picker',
    templateUrl: './location-picker.component.html'
  })
  
export class LocationPickerComponent implements OnInit {
  @Output() onSelect: EventEmitter<ILoc> = new EventEmitter();

  locations: ILoc[] = [];
  locationsForm: UntypedFormGroup;
  isLoading = false;
  itemsCount: number;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {  }

  ngOnInit() {
    this.locationsForm = this.fb.group({
      locInput: null
    })

    this.locationsForm
      .get('locInput')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => this.http.post<ILoc[]>(this.baseUrl + 'api/location/find', { search: value })
          .pipe(
            finalize(() => this.isLoading = false),
          )
        )
      )
      .subscribe(locs => { if (locs != null) this.locations = locs; } );
  }
  
  displayFn(loc: ILoc) {
    if (loc) { return loc.opis; }
  }

  setSelected(loc: ILoc) {
    this.locationsForm.get('locInput').setValue(loc);
  }

  getSelected(): ILoc {
    const selected = this.locationsForm.get('locInput').value;
    let si: ILoc = selected && selected.id ? selected : { id: 0, opis: selected, wolna: true, blokada: false  };
    return si;
  }
  
}
