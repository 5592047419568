import { Component, Inject, ViewChild, AfterViewInit, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ILoc } from '../interfaces/ILoc';
import { LocationSearchMode, UserRole } from '../interfaces/common';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})


export class LocationsComponent implements AfterViewInit, OnInit {
  @ViewChild(MatTable, { static: true }) matTable: MatTable<ILoc>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() dialogMode: boolean;

  private tableData: MatTableDataSource<ILoc>;
  private data: ILoc[];
  itemSearch: string;
  filterMode: LocationSearchMode = LocationSearchMode.All;

  selectedRow: ILoc;
  
  constructor(
    private session: SessionService,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {

    this.http.get<ILoc[]>(this.baseUrl + 'api/locations').subscribe(result => {
      this.data = result;
      this.tableData = new MatTableDataSource<ILoc>(this.data);
      this.tableData.paginator = this.paginator;
      this.tableData.sort = this.sort;
    }, error => console.error(error));
    
  }
  ngOnInit(): void {
    this.session.verify();
  }

  ngAfterViewInit() {
    // this.data.paginator = this.paginator;
  }

  sortData(sort: Sort): void {
    this.selectedRow = null;
    if (sort.direction === '') {
      this.tableData.data = this.data;
      return;
    }

    const sortedData = this.data.slice();
    var that = this;
    sortedData.sort(
      (a: ILoc, b: ILoc) => sort.direction === 'asc'
        ? that._sortAlphanumeric(a[sort.active], b[sort.active])
        : that._sortAlphanumeric(b[sort.active], a[sort.active])
    );
    this.tableData.data = sortedData;
  }

private _sortAlphanumeric(a: string, b: string): number {
  return a.localeCompare(b, 'en', { numeric: true });
}

find(filterValue: string) {
  if (filterValue == undefined) {
    filterValue = '';
  }
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();

  this.tableData.filterPredicate = (model: ILoc, what: string): boolean => {
    return model.opis.toLocaleLowerCase().indexOf(what) >= 0
  };

  this.tableData.filter = filterValue;
  this.matTable.renderRows();
  this.selectedRow = null;

  }

  selectRow(row: ILoc) {
    this.selectedRow = row;
  }  

  clear() {
    this.itemSearch = "";
    this.find("");
  }

  changeMode() {
    let filterData: ILoc[];

    switch(this.filterMode) {
      case LocationSearchMode.All:
        filterData = this.data
        break;
      case LocationSearchMode.OnlyFree:
        filterData = this.data.filter(m => m.wolna);
        break;
      case LocationSearchMode.OnlyOcuppied:
        filterData = this.data.filter(m => !m.wolna);
        break;
    }
    this.tableData.data = filterData;

    this.find(this.itemSearch);
  }

  getRowCss(row: ILoc) {
    return {
      'highlight': this.selectedRow && this.selectedRow == row,
      'locked': row.blokada,
      'free': !row.blokada && row.wolna,
      'occupied': !row.blokada && !row.wolna
    };
  }

  delete(row: ILoc){
    if (this.session.userRole > UserRole.ReadWrite) {
      return;
    }
    this.http.delete(this.baseUrl + 'api/location/delete/' + row.id).subscribe(result => {
      if (result["status"] == "OK") {
        this.tableData.data = this.tableData.data.filter(d => d.id != row.id);
      } else {
        alert(result["status"])
      }
    }, error => console.error(error));
 }

  lock(row: ILoc) {
    if (this.session.userRole > UserRole.ReadWrite) {
      return;
    }
    row.blokada = !row.blokada
    this.http.post<ILoc>(this.baseUrl + 'api/location/save', row).subscribe(result => {
      // TODO:
    }, error => {
      row.blokada = !row.blokada
      console.error(error)
    });
  }

}