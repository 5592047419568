import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IHistoria } from '../interfaces/IHistoria';
import { SessionService } from '../session.service';
import { InflowHistoryDialogComponent } from './inflow-history.dialog.component';

@Component({
  selector: 'app-inflow-history-component',
  templateUrl: './inflow-history.component.html',
  styleUrls: ['./inflow-history.component.css']
})
export class InflowHistoryComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  private tableData: MatTableDataSource<IHistoria>;
  selectedRow: IHistoria;

  constructor(
    public matDialogRef: MatDialogRef<InflowHistoryDialogComponent>,
    private dialog: MatDialog,
    private route: Router,
    http: HttpClient,
    private session: SessionService,
    @Inject('BASE_URL') baseUrl: string) {

    http.get<IHistoria[]>(baseUrl + 'api/inflow/history').subscribe(result => {
      this.tableData = new MatTableDataSource<IHistoria>(result);
      this.tableData.paginator = this.paginator;
    }, error => console.error(error));

  }
  ngOnInit(): void {
    this.session.verify();
  }

  selectRow(row: IHistoria) {
    this.selectedRow = row;
  }

  chooseRow(row: IHistoria) {
    this.selectedRow = row;
    let dialogRef: MatDialogRef<InflowHistoryDialogComponent> = this.dialog.open(InflowHistoryDialogComponent, { width: "960px" });
    dialogRef.componentInstance.history = row;
  }

  edit(item: IHistoria) {
    this.route.navigate(["inflow-edit", item.id]);
  }





}
