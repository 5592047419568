import { Component, Inject, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { SearchMode, ToFind } from '../interfaces/common';

@Component({
    selector: 'app-finder',
    templateUrl: './finder.component.html',
    //   styleUrls: ['./assortment-finder.component.css']
})


export class FinderComponent implements AfterViewInit {
    @Output() search = new EventEmitter<ToFind>();

    itemSearch: string;
    mode: SearchMode = SearchMode.Full;

    constructor() {}

    public clear() {
        this.itemSearch = "";
        this.find("");
    }

    ngAfterViewInit() {
    }

    find(toFind: string) {
        toFind = toFind.trim();
        toFind = toFind.toLowerCase();
        this.search.emit({ what: toFind, mode: this.mode });
    }

    changeMode() {
        this.search.emit({ what: this.itemSearch, mode: this.mode });
    }

}

