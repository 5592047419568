import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IVMagazynBiezacy } from '../interfaces/IVMagazyn';
import { TableUtil } from '../tableUtils';

@Component({
  selector: 'app-outflow-history-positions-component',
  templateUrl: './outflow-history-positions.component.html'
})
export class OutflowHistoryPositionsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() historyId: number;


  private tableData: MatTableDataSource<IVMagazynBiezacy>;
  selectedRow: IVMagazynBiezacy;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    this.http.get<IVMagazynBiezacy[]>(this.baseUrl + 'api/outflow/positions/' + this.historyId).subscribe(result => {
      this.tableData = new MatTableDataSource<IVMagazynBiezacy>(result);
      this.tableData.paginator = this.paginator;
    }, error => console.error(error));
  }

  selectRow(row: IVMagazynBiezacy) {
    this.selectedRow = row;
  }

  export() {
    var te = [];
 		// * matRowDef="let row; columns: ['asortymentKod', 'cdi', 'lokalizacjaOpis', 'lotto', 'asortymentOpis', 'ilosc'];" >
   this.tableData.data.forEach(f => {
      var it = new Object();
      it["Kod"] = f.asortymentKod;
      it["CDI"] = f.cdi;
      it["Lokalizacja"] = f.lokalizacjaOpis;
      it["Lotto"] = f.lotto;
      it["Nazwa"] = f.asortymentOpis;
      it["Ilość"] = f.ilosc;
      te.push(it);
    });
    TableUtil.exportArrayToExcel(te, 'Wydanie');
  }
}
