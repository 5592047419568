import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { UserInfo, UserInfoResponse, UserRole } from "./interfaces/common";

@Injectable({
	providedIn: 'root'
})
export class SessionService {

	private user: UserInfo;

	public get userRole(): UserRole {
		return this.user ? this.user.rola : UserRole.None;
	};

	public get userName() {
		return this.user ? this.user.userName : "";
	};

	constructor(
		private route: Router,
		private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string,
		private cookieService: CookieService) {
		}

	public verify(minRole: UserRole = UserRole.ReadOnly) {
		console.debug('session.verify()');

		const sessionId = this.cookieService.get('SessionId');
		if (sessionId !== null) {
			this.http.get<UserInfoResponse>(this.baseUrl + 'api/sekiur/verify/' + sessionId).subscribe(result => {
				
				if (result.status == "OK") {
					this.user = result.data;
					if (this.user.rola > minRole) {
						this.user = null;
						this.route.navigate(["logon"]);
					}
				} else{
					this.user = null;
					this.route.navigate(["logon"]);
				}
			}, error => {
				console.error(error);
				this.user = null;
				this.route.navigate(["logon"]);
				});
		} else {
			this.user = null;
			this.route.navigate(["logon"]);
		}
	}
}
