import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IAssortHistory } from '../interfaces/IHistoria';
import { TableUtil } from '../tableUtils';

@Component({
  selector: 'app-assortment-history',
  templateUrl: './assortment-history.component.html'
})
export class AssortmentHistoryComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  displayedColumns: string[] = ['paczkaPrzyjeciaOpis', 'dataPrzyjecia', 'ilosc', 'lokalizacjaOpis', 'cdi', 'lotto', 'paczkaWydaniaOpis', 'dataWydania'];

  private tableData: MatTableDataSource<IAssortHistory>;
  private data: IAssortHistory[];

  dateInFrom: Date;
  dateInTo: Date;
  dateOutFrom: Date;
  dateOutTo: Date;
  code: string;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {

    this.route.params.subscribe((params: ParamMap) => {
      this.code = params['code'];
      if (this.code) {
        this.http.get<IAssortHistory[]>(this.baseUrl + 'api/assort/history/' + this.code).subscribe(result => {
          this.data = result;
          this.tableData = new MatTableDataSource<IAssortHistory>(this.data);
          this.tableData.paginator = this.paginator;
          this.tableData.sort = this.sort;
        }, error => console.error(error));
      }
    });
  }

  export() {
    TableUtil.exportArrayToExcel(this.data, "Historia");
    // TableUtil.exportTableToExcel('historyReport', 'Historia')
  }

  clearDateInFrom() {
    this.dateInFrom = null;
    this.applyFilter();
  }

  clearDateInTo() {
    this.dateInTo = null;
    this.applyFilter();
  }

  clearDateOutFrom() {
    this.dateOutFrom = null;
    this.applyFilter();
  }

  clearDateOutTo() {
    this.dateOutTo = null;
    this.applyFilter();
  }

  applyFilter() {

    this.tableData.filterPredicate = (model: IAssortHistory, what: string): boolean => {
      const inDate = this.normalizeDate(new Date(model.dataPrzyjecia));
      const outDate = this.normalizeDate(new Date(model.dataWydania));

      if (this.dateInFrom != null && inDate < this.normalizeDate(this.dateInFrom)) {
        return false;
      }
      
      if (this.dateInTo != null && inDate > this.normalizeDate(this.dateInTo)) {
        return false;
      }
      
      if (this.dateOutFrom != null && outDate < this.normalizeDate(this.dateOutFrom)) {
        return false;
      }
      
      if (this.dateOutTo != null && outDate > this.normalizeDate(this.dateOutTo)) {
        return false;
      }

      return true;
    };
    this.tableData.filter = "!";
  }

  private normalizeDate(d: Date) {
    return d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
  }


}
