import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { IVMagazynBiezacy } from '../interfaces/IVMagazyn';
import { SessionService } from '../session.service';
import { TableUtil } from '../tableUtils';

@Component({
  selector: 'app-stock-report-component',
  templateUrl: './stock-report.component.html'
})
export class StockReportComponent implements OnInit {

  data: IVMagazynBiezacy[];
  razem: number;
  ilePal: number;
  dataRap: Date = new Date();
  loading: boolean = true;

  constructor(
    private session: SessionService,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {

     }

  ngOnInit() {
    this.session.verify();
    this.http.get<IVMagazynBiezacy[]>(this.baseUrl + 'api/reports/stock').subscribe(result => {
      this.data = result;

      this.razem = 0;
      this.data.forEach(d => this.razem += d.ilosc);

      // const ileExcl = this.data.filter(d => d.lokalizacjaOpis == "!").length;
      // if (ileExcl == this.data.length) { // same wykrzykniki
      //   this.ilePal = this.data.length;
      // } else {
      //   this.ilePal = [...new Set(this.data.map(item => item.lokalizacjaOpis))].length;
      // }

      this.http.get<number>(this.baseUrl + 'api/reports/stockpal').subscribe(result => {
        this.ilePal = result;
      }, error => console.error(error));

      this.loading = false;

    }, error => console.error(error));


  }

  export() {
    TableUtil.exportTableToExcel("stockReport");
  }

  print() {
    window.print();
  }
     
}
