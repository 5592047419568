import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { StockComponent } from './stock/stock.component';
import { InflowComponent } from './inflow/inflow.component';
import { InflowHistoryComponent } from './inflow-history/inflow-history.component';
import { OutflowComponent } from './outflow/outflow.component';
import { OutflowHistoryComponent } from './outflow-history/outflow-history.component';
import { EodReportComponent } from './reports/eod-report.component';
import { AssortmentComponent } from './assortment/assortment.component';
import { AssortmentDialogComponent } from './assortment/assortment.dialog.component';
import { AssortmentFinderComponent } from './assortment/assortment-finder.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LocationDialogComponent } from './location/location.dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LocationsComponent } from './location/locations.component';
import { FinderComponent } from './shared/finder.component';
import { StockDialogComponent } from './stock/stock.dialog.component';
import { LocationPickerComponent } from './location/location-picker.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InflowHistoryPositionsComponent } from './inflow-history/inflow-history-positions.component';
import { InflowHistoryDialogComponent } from './inflow-history/inflow-history.dialog.component';
import { InflowPrintoutComponent } from './reports/inflow-printout.component';
import { OutflowPrintoutComponent } from './reports/outflow-printout.component';
import { OutflowHistoryPositionsComponent } from './outflow-history/outflow-history-positions.component';
import { OutflowHistoryDialogComponent } from './outflow-history/outflow-history.dialog.component';
import { StockReportComponent } from './reports/stock-report.component';
import { CdiComponent } from './cdi/cdi.component';
import { AssortmentHistoryComponent } from './assortment/assortment-history.component';
import { LogonComponent } from './logon/logon.component';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        StockComponent,
        InflowComponent,
        InflowHistoryComponent,
        OutflowComponent,
        OutflowHistoryComponent,
        EodReportComponent,
        StockReportComponent,
        LocationsComponent,
        CdiComponent,
        AssortmentComponent,
        AssortmentDialogComponent,
        AssortmentFinderComponent,
        FinderComponent,
        LocationDialogComponent,
        StockDialogComponent,
        LocationPickerComponent,
        InflowHistoryPositionsComponent,
        InflowHistoryDialogComponent,
        InflowPrintoutComponent,
        OutflowPrintoutComponent,
        OutflowHistoryPositionsComponent,
        OutflowHistoryDialogComponent,
        AssortmentHistoryComponent,
        LogonComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        MatDialogModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        RouterModule.forRoot([
            { path: '', component: StockComponent, pathMatch: 'full' },
            { path: 'inflow', component: InflowComponent },
            { path: 'inflow-edit/:id', component: InflowComponent },
            { path: 'inflow-history', component: InflowHistoryComponent },
            { path: 'outflow', component: OutflowComponent },
            { path: 'outflow-edit/:id', component: OutflowComponent },
            { path: 'outflow-history', component: OutflowHistoryComponent },
            { path: 'reports/eod', component: EodReportComponent },
            { path: 'reports/stock', component: StockReportComponent },
            { path: 'reports/inflow/:id', component: InflowPrintoutComponent },
            { path: 'reports/outflow/:id', component: OutflowPrintoutComponent },
            { path: 'locations', component: LocationsComponent },
            { path: 'cdi-locks', component: CdiComponent },
            { path: 'assortment', component: AssortmentComponent },
            { path: 'history/:code', component: AssortmentHistoryComponent },
            { path: 'logon', component: LogonComponent },
            // { path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthorizeGuard] },
        ]),
        BrowserAnimationsModule,
    ],
    exports: [
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        MatDialogModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatTooltipModule
    ],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {
                close: (dialogResult: any) => { }
            }
        },
        { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
        [CookieService]
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
