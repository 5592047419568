import { Component, Inject, ViewChild, AfterViewInit, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FinderComponent } from '../shared/finder.component';
import { SearchMode, ToFind } from '../interfaces/common';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-cdi',
  templateUrl: './cdi.component.html',
  styleUrls: ['./cdi.component.css']
})

export class CdiComponent implements AfterViewInit, OnInit {
  @ViewChild(MatTable, { static: true }) matTable: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(FinderComponent, { static: true }) finder: FinderComponent;

  private tableData: MatTableDataSource<any>;
  private data: string[];
  currentFilter: ToFind;
  newCdi: string;

  
  constructor(
    private session: SessionService,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {

    this.http.get<string[]>(this.baseUrl + 'api/cdi').subscribe(result => {
      this.data = result;
      this.tableData = new MatTableDataSource<any>(this.data);
      this.tableData.paginator = this.paginator;
    }, error => console.error(error));
    
  }
  ngOnInit(): void {
    this.session.verify();
  }

  ngAfterViewInit() {
    this.finder.search.subscribe(sm => {
      this.applyFilter(sm);
    });
  }

  applyFilter(filter: ToFind) {
    filter.what = filter.what.toLowerCase();
    this.tableData.filterPredicate = (model: string, what: string): boolean => {
      switch (filter.mode) {
        case SearchMode.Full:
          return model.toLowerCase().indexOf(what) >= 0;
          break;
        case SearchMode.StartsWith:
          return model.toLowerCase().startsWith(what);
          break;
        case SearchMode.EndWith:
          return model.toLowerCase().endsWith(what);
          break;
      }
      return false;
    };
    this.tableData.filter = filter.what;
    this.currentFilter = filter;
  }

  add() {
    this.http.post<string>(this.baseUrl + 'api/cdi/add', { cdi: this.newCdi }).subscribe(result => {
      if (result["status"] == "OK") {

        const newData = [...this.tableData.data];
        newData.push(this.newCdi);
        this.tableData.data = newData;
        
        this.newCdi = "";
      } else {
        alert(result["status"])
      }
    }, error => console.error(error));
  }

  delete(row: any){
    this.http.delete(this.baseUrl + 'api/cdi/delete/' + row).subscribe(result => {
      if (result["status"] == "OK") {
        this.tableData.data = this.tableData.data.filter(d => d != row);
      } else {
        alert(result["status"])
      }
    }, error => console.error(error));
 }


}