import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { TableUtil } from '../tableUtils';

@Component({
  selector: 'app-eod-report-component',
  templateUrl: './eod-report.component.html'
})
export class EodReportComponent implements OnInit {
  loading: boolean = true;
  data: any[];
  dataRap: Date = new Date();

  constructor(
    private session: SessionService,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string){

    }
    
  ngOnInit() {
      this.session.verify();
      this.http.get<any[]>(this.baseUrl + 'api/reports/eod').subscribe(result => {
        this.data = result;

        this.loading = false;

      }, error => console.error(error));
    }

  export() {
    TableUtil.exportTableToExcel("stockReport");
  }

  print() {
    window.print();
  }
}
