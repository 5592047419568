import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IWydanie } from '../interfaces/IWydanie';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-inflow-printout-component',
  templateUrl: './inflow-printout.component.html'
})
export class InflowPrintoutComponent implements OnInit, AfterViewInit {

  inflowId: string;
  paczka: string;
  dataWz: Date;
  razem: number;
  ilePal: number;
  data: IWydanie[];

  constructor(
    private session: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  ngAfterViewInit(): void {
  }


  ngOnInit(): void {
    this.session.verify();
    this.route.params.subscribe((params: ParamMap) => {
      this.inflowId = params['id'];

      if (this.inflowId) {

        this.http.get<IWydanie[]>(this.baseUrl + 'api/stock/inflowPrint/' + this.inflowId).subscribe(result => {
          this.data = result;

          if (this.data.length < 1) {
            return;
          }

          this.paczka = this.data[0].paczkaPrzyjeciaOpis;
          this.dataWz = this.data[0].data;

          this.razem = 0;
          this.data.forEach(d => this.razem += d.ilosc);
          this.ilePal = this.data[0].ilePal;

          setTimeout(() => {
            window.print();
          });

        }, error => console.error(error));
      };
    });
  }

}
