import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IAssortment } from '../interfaces/IAssortment';
import { IVMagazynBiezacy } from '../interfaces/IVMagazyn';
import { LocationPickerComponent } from '../location/location-picker.component';

@Component({
    selector: 'app-stock-dialog',
    templateUrl: './stock.dialog.component.html'
  })
  
export class StockDialogComponent implements OnInit, AfterViewInit {
  @Output() onSave: EventEmitter<IVMagazynBiezacy> = new EventEmitter();
  @ViewChild(LocationPickerComponent, { static: true }) locationPicker: LocationPickerComponent;
  
  currentItem: IVMagazynBiezacy;

  stockForm = new UntypedFormGroup(
    {
      asortymentKod: new UntypedFormControl(),
      cdi: new UntypedFormControl(),
      lotto: new UntypedFormControl(),
      uwagi: new UntypedFormControl(),
      ilosc: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(1000000)
      ]))
    });

    constructor(
      public matDialogRef: MatDialogRef<StockDialogComponent>,
      private http: HttpClient,
      @Inject('BASE_URL') private baseUrl: string) {  }
  
  ngOnInit() {
    let item = {
      asortymentKod: this.currentItem.asortymentKod,
      cdi: this.currentItem.cdi,
      ilosc: this.currentItem.ilosc,
      lotto: this.currentItem.lotto,
      uwagi: this.currentItem.uwagi
    };

    this.stockForm.setValue(item);
  }

  ngAfterViewInit() {
    this.locationPicker.setSelected({ id: this.currentItem.lokalizacja, opis: this.currentItem.lokalizacjaOpis, wolna: true, blokada: false });
  }
      
  save() {
    this.currentItem.ilosc = this.stockForm.get("ilosc").value;
    var loc = this.locationPicker.getSelected();
    this.currentItem.lokalizacjaOpis = loc.opis;
    this.currentItem.lokalizacja = loc.id;
    this.currentItem.cdi = this.stockForm.get("cdi").value;
    this.currentItem.lotto = this.stockForm.get("lotto").value;
    this.currentItem.uwagi = this.stockForm.get("uwagi").value;

    if (!loc.id) {
      if (!confirm('Taka lokalizacja nie istnieje. Czy dopisać nową?')) {
        return;
      }
    }

    if (this.currentItem.asortymentKod != this.stockForm.get("asortymentKod").value)
    {
      this.http.get<IAssortment>(this.baseUrl + 'api/assort/find/' + this.stockForm.get("asortymentKod").value).subscribe(result => {
        if (result == null) {
          alert('Kod nie istnieje.');
          return;
        } else {
          this.currentItem.asortyment = result.id;
          this.saveCall();
        }
      }, error => console.error(error));
    } else {
      this.saveCall();
    }

  }
  
  private saveCall() {
    this.http.post<IVMagazynBiezacy>(this.baseUrl + 'api/stock/save', this.currentItem).subscribe(result => {
      this.onSave.emit(result);
      this.matDialogRef.close();
    }, error => console.error(error));
  }

  cancel() {
    this.matDialogRef.close();  
  }
}
