import { Component, Inject, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IVMagazynBiezacy } from '../interfaces/IVMagazyn';
import { SelectionModel } from '@angular/cdk/collections';
import { LocationDialogComponent } from '../location/location.dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FinderComponent } from '../shared/finder.component';
import { StockDialogComponent } from './stock.dialog.component';
import { SearchMode, ToFind, UserRole } from '../interfaces/common';
import { ILoc } from '../interfaces/ILoc';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(FinderComponent, { static: true }) finder: FinderComponent;

  tableData: MatTableDataSource<IVMagazynBiezacy>;
  data: IVMagazynBiezacy[];
  selection = new SelectionModel<IVMagazynBiezacy>(true, []);
  currentFilter: ToFind;
  operStatus: string = "";

  selectedRow: IVMagazynBiezacy;

  constructor(
    private session: SessionService,
    public matDialogRef: MatDialogRef<LocationDialogComponent>,
    private dialog: MatDialog,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {

      http.get<IVMagazynBiezacy[]>(baseUrl + 'api/stock/get').subscribe(result => {
        this.data = result;
        this.tableData = new MatTableDataSource<IVMagazynBiezacy>(this.data);

        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;

      }, error => console.error(error));
  }
  ngOnInit(): void {
    this.session.verify();
  }

  ngAfterViewInit() {
    this.finder.search.subscribe(sm => {
      this.applyFilter(sm);
    });
  }

  private _sortAlphanumeric(a: string, b: string): number {
    return a.localeCompare(b, 'en', { numeric: true });
  }

  sortData(sort: Sort): void {
    if (sort.direction === '') {
      this.tableData.data = this.data;
      return;
    }

    const sortedData = this.data.slice();
    sortedData.sort(
      (a: IVMagazynBiezacy, b: IVMagazynBiezacy) => sort.direction === 'asc'
        ? this._sortAlphanumeric(a[sort.active], b[sort.active])
        : this._sortAlphanumeric(b[sort.active], a[sort.active])
    );
    this.tableData.data = sortedData;
  }

  applyFilter(filter: ToFind) {
    filter.what = filter.what.toLowerCase();
    this.tableData.filterPredicate = (model: IVMagazynBiezacy, what: string): boolean => {
      switch (filter.mode) {
        case SearchMode.Full:
          return model.paczkaOpis.toLowerCase().indexOf(what) >= 0 || model.asortymentKod.toLowerCase().indexOf(what) >= 0 || model.lokalizacjaOpis.toLowerCase().indexOf(what) >= 0
            || model.ilosc.toString().indexOf(what) >= 0 || model.cdi.toLowerCase().indexOf(what) >= 0 || model.lotto.toLowerCase().indexOf(what) >= 0
            || model.dokumentBrembo.toLowerCase().indexOf(what) >= 0 || model.dataBrembo.toString().indexOf(what) >= 0 || model.dataPrzyjecia.toString().indexOf(what) >= 0;
          break;
        case SearchMode.StartsWith:
          return model.paczkaOpis.toLowerCase().startsWith(what) || model.asortymentKod.toLowerCase().startsWith(what) || model.lokalizacjaOpis.toLowerCase().startsWith(what)
            || model.ilosc.toString().startsWith(what) || model.cdi.toLowerCase().startsWith(what) || model.lotto.toLowerCase().startsWith(what)
            || model.dokumentBrembo.toLowerCase().startsWith(what) || model.dataBrembo.toString().startsWith(what) || model.dataPrzyjecia.toString().startsWith(what);
          break;
        case SearchMode.EndWith:
          return model.paczkaOpis.toLowerCase().endsWith(what) || model.asortymentKod.toLowerCase().endsWith(what) || model.lokalizacjaOpis.toLowerCase().endsWith(what)
            || model.ilosc.toString().endsWith(what) || model.cdi.toLowerCase().endsWith(what) || model.lotto.toLowerCase().endsWith(what)
            || model.dokumentBrembo.toLowerCase().endsWith(what) || model.dataBrembo.toString().endsWith(what) || model.dataPrzyjecia.toString().endsWith(what);
          break;
      }
      return false;
    };
    this.tableData.filter = filter.what;
    this.currentFilter = filter;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableData.filteredData.forEach(row => this.selection.select(row));
  }

  changeLoc() {
    if (this.session.userRole > UserRole.ReadWrite) {
      return;
    }
    let dialogRef: MatDialogRef<LocationDialogComponent> = this.dialog.open(LocationDialogComponent);
    dialogRef.componentInstance.itemsCount = this.selection.selected.length;
    dialogRef.componentInstance.onSelect.subscribe((newLoc: ILoc) => {

      let tochange = { Location: newLoc, Items: this.selection.selected };
      if (!newLoc.id) {
        if (!confirm('Taka lokalizacja nie istnieje. Czy dopisać nową?')) {
          return;
        }
      }

      this.http.post(this.baseUrl + 'api/stock/location', tochange).subscribe(result => {

        this.selection.selected.forEach(item => {
          item.lokalizacjaOpis = newLoc.opis;
        });
        this.selection.clear();
        this.selection.selected.splice(0, this.selection.selected.length);
      }, error =>  console.debug(error) );
    });
  }

  selectRow(row: IVMagazynBiezacy) {
    this.selectedRow = row;
  }

  chooseRow(row: IVMagazynBiezacy) {
    this.selectedRow = row;
    if (this.session.userRole > UserRole.ReadWrite) {
      return;
    }
    let dialogRef: MatDialogRef<StockDialogComponent> = this.dialog.open(StockDialogComponent, { width: "600px"});
    dialogRef.componentInstance.currentItem = row;
    dialogRef.componentInstance.onSave.subscribe(newItem => {
      console.debug(newItem);
      this.selectedRow = newItem;
    });
  }
}

