import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAssortment } from '../interfaces/IAssortment';

@Component({
    selector: 'app-assortment-finder',
    templateUrl: './assortment-finder.component.html',
    //   styleUrls: ['./assortment-finder.component.css']
})


export class AssortmentFinderComponent {
    @Output() selectedItem = new EventEmitter<IAssortment>();

    itemsFound: IAssortment[];
    itemSearch: string;
    isLoading = false;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string) {
    }

    public SetValue(value) {
        this.itemSearch = value;
    }

    find(toFind: string) {
        toFind = toFind.trim();
        toFind = toFind.toLowerCase();

        this.http.post<IAssortment[]>(this.baseUrl + 'api/assort/find', { search: toFind } ).subscribe(result => {
            if (result.length == 1) {
                this.itemsFound = null;
                this.selectedItem.emit(result[0]);
            } else {
                this.itemsFound = result;
            }
        }, error => console.error(error));

    }

    select(item: IAssortment) {
        this.selectedItem.emit(item);
    }

    displayFn(aso: IAssortment) {
        if (aso) { return aso.kod; }
    }

}
