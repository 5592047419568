import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { ILoc } from '../interfaces/ILoc';
import { LocationPickerComponent } from './location-picker.component';

@Component({
    selector: 'app-location-dialog',
    templateUrl: './location.dialog.component.html'
  })
  
export class LocationDialogComponent implements OnInit {
  @Output() onSelect: EventEmitter<ILoc> = new EventEmitter();
  @ViewChild(LocationPickerComponent, { static: true }) locationPicker: LocationPickerComponent;

  itemsCount: number;

  constructor(
    private fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<LocationDialogComponent>,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  ngOnInit() {
    this.locationPicker.onSelect.subscribe(v => {
      this.select();
    });

  }
  
  displayFn(loc: ILoc) {
    if (loc) { return loc.opis; }
  }

  select() {
    this.onSelect.emit(this.locationPicker.getSelected());
    this.matDialogRef.close();
  }
  
  cancel() {
    this.matDialogRef.close();
  }
}
