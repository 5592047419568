import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IAssortment } from '../interfaces/IAssortment';
import { IHistoria } from '../interfaces/IHistoria';
import { InflowHistoryPositionsComponent } from './inflow-history-positions.component';

@Component({
    selector: 'app-inflow-history-dialog',
    templateUrl: './inflow-history.dialog.component.html'
  })
  
export class InflowHistoryDialogComponent implements OnInit {
  @ViewChild(InflowHistoryPositionsComponent, { static: true }) positions: InflowHistoryPositionsComponent

  selectedItem: IAssortment;
  history: IHistoria;

  constructor(public matDialogRef: MatDialogRef<InflowHistoryDialogComponent>,
    @Inject('BASE_URL') private baseUrl: string) {  }

  ngOnInit() {
    this.positions.historyId = this.history.id;
  }
  
  select() {
    this.matDialogRef.close();
  }
  
  cancel() {
    this.matDialogRef.close();  
  }

  print() {
    window.open(this.baseUrl + 'reports/inflow/' + this.positions.historyId);
  }

}
