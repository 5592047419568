import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserInfo, UserInfoResponse } from '../interfaces/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.css']
})

export class LogonComponent implements OnInit {
  
  userFormControl = new UntypedFormControl('', [Validators.required]);
  pwdFormControl = new UntypedFormControl('', [Validators.required]);

  user: string;
  pwd: string;

  loginError: boolean;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: Router,
    private cookieService: CookieService) {    
  }
  ngOnInit(): void {
    this.cookieService.delete('SessionId');
  }

  logon() {
    this.userFormControl.markAllAsTouched();
    this.pwdFormControl.markAllAsTouched();

    if (!this.user || !this.pwd) {
      return;
    }
    this.loginError = false;

    this.http.post<UserInfoResponse>(this.baseUrl + 'api/sekiur/logon/', { userName: this.user, password: this.pwd }).subscribe(result => {

      if (result.status != "OK") {
        this.loginError = true;
      } else {
        this.cookieService.set('SessionId', result.data.sid);
        this.route.navigate(["/"]);
      }
    }, error => {
      console.error(error);
    });
  }

}